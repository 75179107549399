import {fil} from "date-fns/locale";

export default class EventManager {

    constructor()
    {
        this.registeredEvents = {};
    }

    createEvent = (id, name, description =null, detail = {}) => {
        this.registeredEvents[id] = {
            name: name,
            description: description,
            event: new CustomEvent(id, {detail: detail})
        };
        return this;
    }

    triggerEvent(id){
        window.dispatchEvent(this.registeredEvents[id].event);
    }

    subscribe(id, callback = () => {}) {
        let filteredEvents = this.list(id);

        Object.keys(this.registeredEvents).forEach((key) => {
            if(filteredEvents.includes(key)) {
                window.addEventListener(key, callback);
            }
        })
    }

    list = (needle) => {
        return Object.keys(this.registeredEvents)
            .filter(id => id.indexOf(needle) == 0)
    }
};
